import loadable from '@loadable/component';
import Spinner from '../../components/Spinner/Spinner';

const LoadableLiveGridFavoritesContainer = loadable(
  () => import('./components/LiveGridFavoritesContainer'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableLiveGridFavoritesContainer;
