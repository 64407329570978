import type { JSX } from 'react';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { subscribeUrlLiveSelector } from '../../../store/slices/application-selectors';
import { ProspectLoginMessage } from './ProspectLoginMessage';

export function ProspectLoginMessageConnected(): JSX.Element {
  const subscribeUrlLive = useInvariantSelector(subscribeUrlLiveSelector);

  return <ProspectLoginMessage subscribeUrlLive={subscribeUrlLive} />;
}
