import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import { Media, MediaOverlay } from '@dce-front/dive';
import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import { DIMENSIONS } from '@dce-front/onewebapp-dive-utils';
import type { Ratio } from '@dce-front/onewebapp-utils';
import type { JSX } from 'react';
import { useMemo } from 'react';
import { StrateMode } from '../../../constants/strates';
import { getContentAriaLabel } from '../../../helpers/contents/contents-helper';
import {
  formatLiveGridPlayerData,
  formatLiveTrackingObject,
} from '../../../helpers/liveTV/liveTV-helper';
import { useTranslation } from '../../../lang';
import Linker from '../../Linker/Linker';
import { MediaFooterWrapper } from '../../MediaFooterWrapper/MediaFooterWrapper';
import DiveProgressBar from '../../ProgressBar/DiveProgressBar';
import StartOverButton from '../../StartOverButton/StartOverButton';
import ThumborMediaImage from '../../ThumborMediaImage/ThumborMediaImage';

export type LiveGridCardProps = {
  channel: ApiV2BroadcastChannel;
  context?: ApiV2Context;
  ratio: Ratio;
};

function LiveGridCard({
  channel,
  context,
  ratio,
}: LiveGridCardProps): JSX.Element {
  const { t } = useTranslation();

  const tracking = useMemo(
    () => formatLiveTrackingObject({ channel, type: 'Live TV' }),
    [channel],
  );

  const { onClick, ...rest } = useMemo(
    () =>
      formatLiveGridPlayerData(channel.epgID, { ...context, ...tracking }) ||
      {},
    [channel, context, tracking],
  );

  const startOverTitle = channel?.contents?.[0]?.title || '';

  const content = channel.contents && channel.contents[0];
  const title = content ? content.title : t('LiveGrid.cards.genericTitle');
  const subtitle = content
    ? content.subtitle
    : t('LiveGrid.cards.genericSubtitle');

  const hasContentTime = content?.startTime && content?.endTime;

  const logoChannel = useMemo(
    () =>
      channel.URLLogoChannel ? { default: channel.URLLogoChannel } : undefined,
    [channel],
  );

  const ariaLabel = content?.altText ? getContentAriaLabel(content) : title;

  return (
    <Linker
      // `rest` contains an **channel** object with an **epgID** property
      // used in the `launchPlayerFullScreen` Linker function
      // ⚠️ All this stuff isn't properly typed
      data={{ mainOnClick: onClick, ...rest }}
      title={title}
      objKey={StrateMode.LiveTv}
      ariaLabel={ariaLabel}
    >
      <Media
        aspectRatio={ratio}
        aria-label={title}
        image={
          content?.URLImage ? (
            <ThumborMediaImage
              dimensions={
                $_BUILD_RENDERMODE_CSR
                  ? DIMENSIONS.LIVE_GRID_CARD.large
                  : (DIMENSIONS as any)[ratio].normal
              }
              url={content?.URLImage}
              alt={content?.title}
              showFadeIn={!$_BUILD_RENDERMODE_CSR}
            />
          ) : undefined
        }
        // Image loading animation
        showPlaceholderAnim={
          // Opt-out of costly placeholder animation on TV
          !$_BUILD_RENDERMODE_CSR
        }
        overlay={
          <MediaOverlay
            progressBar={
              hasContentTime ? (
                <DiveProgressBar
                  startTime={content.startTime}
                  endTime={content.endTime}
                  isLive
                />
              ) : undefined
            }
            logo={
              logoChannel ? (
                <ThumborMediaImage
                  dimensions={DIMENSIONS.LOGO_CHANNEL.large}
                  url={logoChannel.default}
                  alt={channel.Name}
                />
              ) : undefined
            }
          />
        }
        footer={
          title || subtitle ? (
            <MediaFooterWrapper
              title={title}
              subtitle={subtitle}
              actionableElement={
                <StartOverButton
                  context={context}
                  channel={channel}
                  title={startOverTitle}
                />
              }
              titleDisplayMode={TitleDisplayMode.All}
            />
          ) : undefined
        }
      />
    </Linker>
  );
}

export default LiveGridCard;
