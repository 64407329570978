import type { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { Binder } from '@dce-front/one-navigation';
import classNames from 'classnames';
import type { JSX } from 'react';
import IconTvCheck from '../../../assets/svg/tvCheck.svg';
import IconTvCheckTv from '../../../assets/svg/tvCheckTv.svg';
import { useBinderMiddleware } from '../../../helpers/hooks/useBinderMiddleware';
import { getMiddlewareLiveGridPerso } from '../../../helpers/oneNavigation/middleware';
import { useTranslation } from '../../../lang';
import LiveGridEditFavoritesChannels, {
  LiveGridFavoritesButtonTypes,
} from '../LiveGridEditFavoritesChannels/LiveGridEditFavoritesChannels';
import styles from './LiveGridPerso.css';

export type LiveGridPersoProps = {
  isEmptyState?: boolean;
  liveGridPageTracking?: Tracking;
  refetchLiveGridRequest?: () => void;
};

function LiveGridPerso({
  isEmptyState = false,
  liveGridPageTracking,
  refetchLiveGridRequest = () => {},
}: LiveGridPersoProps): JSX.Element {
  const { t } = useTranslation();

  const middlewareLiveGridPerso = useBinderMiddleware(
    getMiddlewareLiveGridPerso,
  );

  return (
    <div className={classNames(styles.LiveGridPerso, 'LiveGridPerso')}>
      {isEmptyState && (
        <div className={styles.LiveGridPerso__favoritesWrap}>
          {!$_BUILD_RENDERMODE_CSR ? (
            <div className={styles.LiveGridPerso__favoritesIconWrap}>
              <IconTvCheck data-testid="iconTvCheck" />
            </div>
          ) : (
            <IconTvCheckTv data-testid="iconTvCheckTv" />
          )}
          <h2 className={styles.LiveGridPerso__favoritesHeading}>
            {t('LiveGrid.favorites.emptyStateHeading')}
          </h2>
          <p className={styles.LiveGridPerso__text}>
            {t('LiveGrid.favorites.emptyStateText')}
          </p>
          <Binder middleware={middlewareLiveGridPerso}>
            <LiveGridEditFavoritesChannels
              liveGridPageTracking={liveGridPageTracking}
              refetchLiveGridRequest={refetchLiveGridRequest}
              type={LiveGridFavoritesButtonTypes.EMPTY_STATE}
            />
          </Binder>
        </div>
      )}
      {!isEmptyState && (
        <div className={classNames(styles.LiveGridPerso__editButtonWrap)}>
          <Binder middleware={middlewareLiveGridPerso}>
            <LiveGridEditFavoritesChannels
              liveGridPageTracking={liveGridPageTracking}
              refetchLiveGridRequest={refetchLiveGridRequest}
            />
          </Binder>
        </div>
      )}
    </div>
  );
}

export default LiveGridPerso;
